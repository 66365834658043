<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Pasien</b> Klinik Hayandra</div>
      <b-button
        squared
        variant="success"
        @click="$router.push('/patient/add')"
        v-if="manipulateBtn == true"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body p-5">
            <div class="row">
              <div class="col-md-12">
                <b-button
                  v-b-toggle.collapse-1
                  class="w-100 btn-purple"
                  @click="open"
                  >{{ textBtn }}</b-button
                >

                <b-collapse id="collapse-1" class="mt-4">
                  <span class="font-weight-bold mb-1">Pasien Baru Terdaftar :</span>
                  <div class="row">
                    <!-- <div class="col-md-6">
                      <div class="card" style="min-height: 110px">
                        <div class="card-body p-4 text-center">
                          <div class="row">
                            <div
                              class="
                                col-md-4
                                d-flex
                                align-self-center
                                justify-content-center
                              "
                            >
                              <div
                                class="text-center"
                                style="
                                  border-radius: 25px;
                                  background-color: #f6d5e5;
                                  width: 50px;
                                "
                              >
                                <i
                                  class="fas fa-user"
                                  style="
                                    margin: 15px;
                                    font-size: 20px;
                                    color: #a0225f;
                                  "
                                ></i>
                              </div>
                            </div>
                            <div class="col-md-8">
                              <span class="text-teal">Pasien Terdaftar</span>
                              <h4 class="d-block mt-2" style="font-size: 25px">
                                {{
                                  parseInt(items.allPatient).toLocaleString(
                                    "id-ID"
                                  )
                                }}
                              </h4>
                            </div>
                          </div>
                          <div class="row notes">
                            <span class="text-notes font-weight-bolder"
                              >Semua Periode</span
                            >
                          </div>
                        </div>
                      </div>
                    </div> -->

                    <div class="col-md-3">
                      <div class="card" style="min-height: 80px">
                        <div class="card-body p-4 text-center">
                          <div class="row">
                            <div class="col-md-12">
                              <span class="text-teal">Hari Ini</span>
                              <h4 class="d-block mt-2" style="font-size: 25px">
                                {{
                                  parseInt(items.thisDay).toLocaleString(
                                    "id-ID"
                                  )
                                }}
                              </h4>
                            </div>
                          </div>
                          <div class="row notes">
                            <span class="text-notes font-weight-bolder"
                              >Pasien</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="card" style="min-height: 80px">
                        <div class="card-body p-4 text-center">
                          <div class="row">
                            <div class="col-md-12">
                              <span class="text-teal">Bulan Ini</span>
                              <h4 class="d-block mt-2" style="font-size: 25px">
                                {{
                                  parseInt(items.thisMonth).toLocaleString(
                                    "id-ID"
                                  )
                                }}
                              </h4>
                            </div>
                          </div>

                          <div class="row notes">
                            <span class="text-notes font-weight-bolder"
                              >Pasien</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="card" style="min-height: 80px">
                        <div class="card-body p-4 text-center">
                          <div class="row">
                            <div class="col-md-12">
                              <span class="text-teal">Tahun Ini</span>
                              <h4 class="d-block mt-2" style="font-size: 25px">
                                {{
                                  parseInt(items.thisYear).toLocaleString(
                                    "id-ID"
                                  )
                                }}
                              </h4>
                            </div>
                          </div>

                          <div class="row notes">
                            <span class="text-notes font-weight-bolder"
                              >Pasien</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="card" style="min-height: 80px">
                        <div class="card-body p-4 text-center">
                          <div class="row">
                            <div class="col-md-12">
                              <span class="text-teal">Semua Periode</span>
                              <h4 class="d-block mt-2" style="font-size: 25px">
                                {{
                                  parseInt(items.all).toLocaleString("id-ID")
                                }}
                              </h4>
                            </div>
                          </div>

                          <div class="row notes">
                            <span class="text-notes font-weight-bolder"
                              >Pasien</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <Table purpose="master" />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import Card from "@/view/content/Card.vue";
import Table from "@/component/patients/Table.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    Card,
    Table,
  },

  data() {
    return {
      manipulateBtn: false,
      items: [],
      textBtn: "Tampilkan Detail",
      collapseOpen: false,
    };
  },

  methods: {
    async pagination() {
      let response = await module.get("overview-patient");
      this.items = response;
    },

    open() {
      if (this.collapseOpen == false) {
        this.collapseOpen = true;
        this.textBtn = "Sembunyikan Detail";
      } else {
        this.collapseOpen = false;
        this.textBtn = "Tampilkan Detail";
      }
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        console.log("looping");
        if (access_right[a] == "3002") {
          this.manipulateBtn = true;
        }
      }
    },
  },

  mounted() {
    this.setActiveMenu();
    this.pagination();
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pasien" },
      { title: "Daftar Pasien" },
    ]);
  },
};
</script>

<style>
.btn-outline-purple {
  border: 1px solid #551871;
  color: #551871;
}

.btn-outline-purple:hover {
  background-color: #993366 !important;
  color: white;
}

.btn-purple {
  background-color: #993366 !important;
  color: white !important;
}

.btn-purple:hover {
  background-color: #652143 !important;
  color: white !important;
}

.text-notes {
  color: #bfc0c5 !important;
  font-size: 10px;
}

.notes {
  position: absolute;
  bottom: 14px;
  right: 20px;
}

/* .btn-purple:hover{
  background-color: #551871;
  color: white;
} */
</style>
